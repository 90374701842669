import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { DealAspects } from 'api/deals/types';

import { ChannelSelect } from './ChannelSelect';
import { ALL_INVENTORY, RON_DPG_INVENTORY, RON_PPN_INVENTORY } from './constants';
import { SiteSelect } from './SiteSelect';

import { theme } from '../../../theme';
import { arraysContainSameItems } from '../../../utils/arrayUtils';
import { SelectField } from '../../form/fields/select-field';

export type Props = {
  inventory: DealAspects['inventory'];
  environments: DealAspects['environments'] | null | undefined;
  format: string | null | undefined;
  onChange(inventory: DealAspects['inventory']): void;
};

type Type = 'all' | 'rondpg' | 'ronppn' | 'channel' | 'site';

const allInventoryIds = [
  ALL_INVENTORY.channelIds.app,
  ALL_INVENTORY.channelIds.web,
  [...ALL_INVENTORY.channelIds.app, ...ALL_INVENTORY.channelIds.web],
];

const dpgInventoryIds = [
  RON_DPG_INVENTORY.channelIds.app,
  RON_DPG_INVENTORY.channelIds.web,
  [...RON_DPG_INVENTORY.channelIds.app, ...RON_DPG_INVENTORY.channelIds.web],
];
const ppnInventoryIds = [
  RON_PPN_INVENTORY.channelIds.app,
  RON_PPN_INVENTORY.channelIds.web,
  [...RON_PPN_INVENTORY.channelIds.app, ...RON_PPN_INVENTORY.channelIds.web],
];

function getType(inventory: DealAspects['inventory']): Type {
  // Support for old deals. New deals should always contain inventory
  if (!inventory || inventory.length === 0) {
    return 'all';
  }

  if (inventory && inventory.length === 1 && inventory[0].type === 'channel') {
    const channelInventory = inventory[0];

    if (
      allInventoryIds.some((idList) =>
        arraysContainSameItems(channelInventory.channelIds, idList)
      )
    ) {
      return 'all';
    }

    if (
      dpgInventoryIds.some((idList) =>
        arraysContainSameItems(channelInventory.channelIds, idList)
      )
    ) {
      return 'rondpg';
    }

    if (
      ppnInventoryIds.some((idList) =>
        arraysContainSameItems(channelInventory.channelIds, idList)
      )
    ) {
      return 'ronppn';
    }
  }

  return inventory[0].type;
}

function getDefaultInventoryForType(type: Type): DealAspects['inventory'] {
  switch (type) {
    case 'all':
      return [
        {
          ...ALL_INVENTORY,
          channelIds: [...ALL_INVENTORY.channelIds.app, ...ALL_INVENTORY.channelIds.web],
        },
      ];
    case 'rondpg':
      return [
        {
          ...RON_DPG_INVENTORY,
          channelIds: [
            ...RON_DPG_INVENTORY.channelIds.app,
            ...RON_DPG_INVENTORY.channelIds.web,
          ],
        },
      ];
    case 'ronppn':
      return [
        {
          ...RON_PPN_INVENTORY,
          channelIds: [
            ...RON_PPN_INVENTORY.channelIds.app,
            ...RON_PPN_INVENTORY.channelIds.web,
          ],
        },
      ];
    case 'channel':
    case 'site':
      return [];
  }
}

export const InventoryDialogContent: React.FC<Props> = ({
  inventory,
  environments,
  format,
  onChange,
}) => {
  const intl = useIntl();
  const [type, setType] = useState(() => getType(inventory));
  const updateType = (newType: Type) => {
    setType(newType);

    if (newType !== type) {
      onChange(getDefaultInventoryForType(newType));
    }
  };

  return (
    <>
      <SelectField
        fullWidth
        variant="outlined"
        sx={{ marginBottom: theme.spacing(2) }}
        value={type}
        options={['all', 'rondpg', 'ronppn', 'channel', 'site'].map((type) => ({
          value: type,
          label: intl.formatMessage({
            id: `deal.inventory.dialog.type.${type}`,
          }),
        }))}
        onChange={(e) => updateType(e.target.value as Type)}
      />
      {type === 'channel' && (
        <ChannelSelect
          inventory={inventory}
          environments={environments}
          format={format}
          onChange={onChange}
        />
      )}
      {type === 'site' && <SiteSelect inventory={inventory} onChange={onChange} />}
    </>
  );
};
