import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';

import { DatalabAudienceInfo } from './DatalabAudienceInfo';
import { Loader } from './Loader';
import { useShareKeyAudiencesValidation } from './useShareKeyAudiences';
import { ErrorCodes, mapErrorCodeToError } from './utils';

import { DatalabAudience, DatalabAudienceGroup } from '../../api/datalab/types';
import { Button } from '../button';
import { Dialog, DialogContainer, DialogFooter, DialogTitle } from '../dialog2';
import { TextField } from '../form/fields/text-field/TextField';
import { InlineAlert } from '../inline-alert';

export type Props = {
  audienceGroups: DatalabAudienceGroup[];
  triggerEl: React.ReactElement;
  onAdd: (audienceGroup: DatalabAudienceGroup) => void;
};

export const DatalabAudienceAddDialog: React.FC<Props> = ({
  onAdd,
  triggerEl,
  audienceGroups,
}) => {
  const intl = useIntl();
  const [shareKey, setShareKey] = useState('');
  const [audiences, setAudiences] = useState<DatalabAudience[] | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCodes | null>(null);
  const { validate, isLoading, isSuccess } = useShareKeyAudiencesValidation();
  const onCleanup = useCallback(() => {
    setShareKey('');
    setAudiences(null);
    setErrorCode(null);
  }, [setAudiences, setShareKey, setErrorCode]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onValidate = useCallback(
    debounce(async (key: string) => {
      try {
        if (audienceGroups.some((group) => group.shareKey === key)) {
          setErrorCode(ErrorCodes.DUPLICATE);
          return;
        }

        const data = await validate(key);

        setErrorCode(null);
        setAudiences(data.audiences);
      } catch (e) {
        setErrorCode(e as ErrorCodes);
      }
    }, 500),
    [validate, setAudiences, setErrorCode, audienceGroups]
  );

  return (
    <Dialog triggerEl={triggerEl}>
      {({ onDialogClose }) => (
        <DialogContainer
          header={
            <DialogTitle
              headerLabel="advertiser.new.datalab.audiences.dialog.title"
              subHeaderLabel="advertiser.new.datalab.audiences.dialog.description"
              handleClose={() => {
                onCleanup();
                onDialogClose();
              }}
            />
          }
          footer={
            <DialogFooter
              submitButtonLabel="advertiser.new.datalab.audiences.dialog.submit"
              disabled={isLoading || !isSuccess || !!errorCode || !shareKey}
              handleSubmit={() => {
                onAdd({ shareKey });
                onCleanup();
                onDialogClose();
              }}
              handleCancel={() => {
                onCleanup();
                onDialogClose();
              }}
            />
          }
        >
          <Grid container mb={3} pl={2}>
            <TextField
              fullWidth
              value={shareKey}
              variant="outlined"
              onChange={(e) => {
                setShareKey(e.target.value);
                onValidate(e.target.value);
              }}
            />
            {isLoading && <Loader />}
            {!isLoading && !errorCode && audiences !== null && (
              <DatalabAudienceInfo datalabAudiences={audiences} />
            )}
            {!isLoading && errorCode !== null && (
              <Grid item xs={11.5} mt={2}>
                <InlineAlert severity="error">
                  {mapErrorCodeToError(intl, errorCode)}
                  {(errorCode === ErrorCodes.CONNECTION_ERROR ||
                    errorCode === ErrorCodes.UNKNOWN) && (
                    <>
                      <br />
                      <Button
                        variant="text"
                        size="small"
                        sx={{ marginTop: '8px' }}
                        onClick={(event) => {
                          event.preventDefault();
                          onValidate(shareKey);
                        }}
                      >
                        <FormattedMessage id="error.try.again" />
                      </Button>
                    </>
                  )}
                </InlineAlert>
              </Grid>
            )}
          </Grid>
        </DialogContainer>
      )}
    </Dialog>
  );
};
