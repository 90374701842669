import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Advertiser } from '../../api/advertisers/types';
import { Agency } from '../../api/agencies/types';
import { Buyer, Deal, DealAspects } from '../../api/deals/types';
import { User } from '../../api/users/types';
import { ALL_INVENTORY } from '../../components/deal-form-item/inventory-field/constants';
import { isSameBuyer } from '../../components/deal-form-item/util';
import { DealsForm } from '../../components/deals-form/DealsForm';
import { Icon } from '../../components/icon';
import { SectionTitle } from '../../components/section-title';
import { useUser } from '../../utils/keycloak/KeycloakProvider';
import { copyObjectProperties } from '../../utils/objectUtils';

export type CreateDealsState = {
  deals: Array<Partial<Deal>>;
  error?: unknown;
};

const DEAL_KEYS: Array<keyof DealAspects> = [
  'agency',
  'advertiser',
  'buyer',
  'inventory',
  'targeting',
  'format',
  'devices',
  'environments',
];

function copyDealAspects(deal: Partial<Deal>): Optional<DealAspects> {
  return copyObjectProperties(deal as Optional<DealAspects>, DEAL_KEYS);
}

export const CreateDeals = (): JSX.Element => {
  const { state } = useLocation<CreateDealsState>();
  const user = useUser();
  const getDefaultBuyer = (user?: User, defaultAgency?: Agency) => {
    if (user?.buyers?.length) {
      return (
        user.buyers!.find((userBuyer: Buyer) =>
          defaultAgency?.buyers?.some((agencyBuyer: Buyer) =>
            isSameBuyer(userBuyer, agencyBuyer)
          )
        ) || null
      );
    }

    return defaultAgency?.buyers?.[0];
  };
  const getDefaultAdvertiser = (user?: User, defaultAgency?: Agency) => {
    if (user?.advertisers?.length) {
      return (
        user.advertisers!.find((userAdv: Advertiser) =>
          defaultAgency?.advertisers?.some(
            (agencyAdv: Advertiser) => userAdv.id === agencyAdv.id
          )
        ) || null
      );
    }

    return defaultAgency?.advertisers?.[0];
  };
  const defaultInventory = {
    ...ALL_INVENTORY,
    channelIds: [...ALL_INVENTORY.channelIds.app, ...ALL_INVENTORY.channelIds.web],
  };
  const deals = useMemo(() => {
    const ds: Array<Partial<Deal>> =
      state?.deals?.map((d) => ({ ...copyDealAspects(d), ownedBy: d.ownedBy })) ?? [];

    if (ds.length === 0) {
      const defaultAgency = user?.agencies?.[0];
      const defaultAdvertiser = getDefaultAdvertiser(user, defaultAgency);
      const defaultBuyer = getDefaultBuyer(user, defaultAgency);
      const placeholderDeal: Partial<Deal> = {
        ownedBy: defaultAgency?.id,
        agency: defaultAgency?.dealNameAlias ?? defaultAgency?.name,
        advertiser: defaultAdvertiser,
        buyer: defaultBuyer,
        inventory: [defaultInventory],
      };

      ds.push(placeholderDeal);
    }

    return ds;
  }, [state, user]);

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="deals.create.title" />}
          description={<FormattedMessage id="deals.create.description" />}
          sx={{ marginBottom: '32px' }}
        />
        <DealsForm deals={deals} canAddItems={true} />
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};
