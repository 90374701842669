import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Divider, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { DealAspects, Environment } from 'api/deals/types';

import { ChannelIds, INVENTORY_CHANNELS } from './constants';
import { styles } from './styles';

import { ProductSpecs, productSpecs } from '../../../static/productSpecs';

export type Props = {
  inventory: DealAspects['inventory'];
  environments: Environment[] | null | undefined;
  format: string | null | undefined;
  onChange(inventory: DealAspects['inventory']): void;
};

export const ChannelSelect: React.FC<Props> = ({
  inventory,
  environments,
  format,
  onChange,
}) => {
  const isDisabled = (
    channelIds: ChannelIds,
    supportedFormats: ProductSpecs[] | undefined
  ) => {
    if (
      environments &&
      !environments.every((env) => channelIds[env] && channelIds[env].length > 0)
    ) {
      return true;
    }
    if (
      supportedFormats &&
      format &&
      supportedFormats.every(
        (supportedFormat) => productSpecs[supportedFormat].name !== format
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {INVENTORY_CHANNELS.map(
        ({ name, description, channelIds, supportedFormats }, idx) => {
          const checked = inventory?.some((i) => i.value === name) ?? false;
          const disabled = isDisabled(channelIds, supportedFormats);

          return (
            <React.Fragment key={name}>
              <FormControlLabel
                sx={styles.channelCheckbox}
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChange([
                          ...(inventory ?? []),
                          {
                            type: 'channel',
                            value: name,
                            channelIds: Object.values(channelIds)
                              .flatMap((idGroup) => idGroup ?? [])
                              .map((idGroup) => idGroup.id),
                          },
                        ]);
                      } else {
                        onChange(inventory?.filter((i) => i.value !== name) ?? []);
                      }
                    }}
                  />
                }
                label={
                  <>
                    {disabled && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ whiteSpace: 'pre-wrap' }}
                      >
                        <FormattedMessage id="deal.inventory.dialog.type.channel.disabled" />
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ whiteSpace: 'pre-wrap' }}
                    >
                      {description}
                    </Typography>
                  </>
                }
              />
              {idx !== INVENTORY_CHANNELS.length - 1 && <Divider />}
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
