import isEqual from 'lodash/isEqual';

import {
  ALL_INVENTORY,
  INVENTORY_CHANNELS,
  RON_DPG_INVENTORY,
  RON_PPN_INVENTORY,
} from './inventory-field/constants';

import { Advertiser } from '../../api/advertisers/types';
import {
  Buyer,
  DealAspects,
  Device,
  Environment,
  Inventory,
  InventoryChannel,
} from '../../api/deals/types';
import { User } from '../../api/users/types';
import { ProductSpec, productSpecs } from '../../static/productSpecs';
import { arrayContainsAny } from '../../utils/arrayUtils';
import { DealEntry } from '../deals-form/util';
import { Option } from '../form/fields/select-field/SelectField';

export function canUseProductSpec(
  spec: ProductSpec,
  deal: Optional<DealAspects>
): boolean {
  return (
    canUseProductSpecForDevices(spec, deal.devices) &&
    canUseProductSpecForEnvironments(spec, deal.environments) &&
    canUseProductSpecForInventory(spec, deal.inventory)
  );
}

function canUseProductSpecForDevices(
  spec: ProductSpec,
  devices: Optional<DealAspects>['devices']
): boolean {
  return arrayContainsAny(spec.devices, devices);
}

function canUseProductSpecForEnvironments(
  spec: ProductSpec,
  environments: Optional<DealAspects>['environments']
): boolean {
  return arrayContainsAny(spec.environments, environments);
}

export function canUseProductSpecForInventory(
  spec: ProductSpec,
  inventory: Optional<DealAspects>['inventory']
): boolean {
  return (
    inventory?.every((i) => {
      // No limits on other types
      if (i.type !== 'channel') {
        return true;
      }

      // Check if any channel has a limit on the format
      return (
        INVENTORY_CHANNELS.find((ch) => ch.name === i.value)?.supportedFormats?.some(
          (f) => productSpecs[f].name === spec.name
        ) ?? true
      );
    }) ?? true
  );
}

export function getFormatOptions(deal: Optional<DealAspects>): Array<Option> {
  return Object.values(productSpecs)
    .map((productSpec) => ({
      key: productSpec.name,
      value: productSpec,
      label: getFormatLabel(productSpec),
      disabled: !canUseProductSpec(productSpec, deal),
    }))
    .sort((a, b) => {
      if (a.disabled === b.disabled) return a.label.localeCompare(b.label);

      return a.disabled ? 1 : -1;
    });
}

function getFormatLabel(spec?: ProductSpec | null) {
  if (!spec) {
    return '';
  }

  return `${spec!.name} (${spec!.format})`;
}

export function cleanAndUpdateDevices(
  devices: Device[],
  deal: Optional<DealAspects>,
  prodSpec: ProductSpec | null
): Optional<DealAspects> {
  const newDeal: Optional<DealAspects> = { ...deal, devices };

  // remove format if it does not fit the chosen devices
  if (
    newDeal.format &&
    devices.some((device) => !(prodSpec?.devices.includes(device) ?? true))
  ) {
    delete newDeal.format;
  }

  return newDeal;
}

export function cleanAndUpdateInventory(
  inventory: Inventory[] | null | undefined,
  deal: Optional<DealAspects>
): Optional<DealAspects> {
  const newDeal = { ...deal, inventory };

  return cleanInventory(newDeal);
}

export function cleanAndUpdateEnvironment(
  environments: Environment[],
  deal: Optional<DealAspects>,
  prodSpec: ProductSpec | null
): Optional<DealAspects> {
  const newDeal: Optional<DealAspects> = { ...deal, environments };

  // remove format if it does not fit the chosen environment
  if (
    newDeal.format &&
    environments.some((env) => !(prodSpec?.environments.includes(env) ?? true))
  ) {
    delete newDeal.format;
  }

  // remove inventory if it does not fit the chosen environment
  if (newDeal.inventory) {
    newDeal.inventory = newDeal.inventory.filter((inv) => {
      if (inv.type !== 'channel') {
        return true;
      }

      const ronChannelIds = [RON_DPG_INVENTORY, RON_PPN_INVENTORY, ALL_INVENTORY].find(
        (channel) => channel?.value === inv.value
      )?.channelIds;

      if (ronChannelIds) {
        return true;
      }

      const channelIds = INVENTORY_CHANNELS.find(
        (channel) => channel?.name === inv.value
      )?.channelIds;

      return newDeal.environments!.some((env) =>
        Object.keys(channelIds ?? {}).includes(env)
      );
    });
  }

  return cleanInventory(newDeal);
}

export function cleanAndUpdateFormat(
  formatSpec: ProductSpec,
  deal: Optional<DealAspects>
): Optional<DealAspects> {
  const newDeal = { ...deal, format: formatSpec.name };

  // make devices more restricted if required by format
  if (newDeal.devices) {
    if (formatSpec.devices.length < newDeal.devices.length) {
      newDeal.devices = formatSpec.devices;
    }
  }

  // make environments more restricted if required by format
  if (newDeal.environments) {
    if (formatSpec.environments.length < newDeal.environments.length) {
      newDeal.environments = formatSpec.environments;
    }
  }

  return cleanInventory(newDeal);
}

export function getProductSpec(deal: Optional<DealAspects>) {
  return Object.values(productSpecs).find((spec) => spec.name === deal.format);
}

export function calculatePriceFloor(deal: Optional<DealAspects>): string {
  return formatPriceFloor(getProductSpec(deal)?.priceFloor ?? null);
}

export function formatPriceFloor(priceFloor: number | null): string {
  if (priceFloor === null) {
    return '€ ...';
  }

  return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
    priceFloor / 100
  );
}

export function isSameBuyer(buyer1: Buyer, buyer2: Buyer) {
  return (
    buyer1.dsp === buyer2.dsp &&
    buyer1.seat === buyer2.seat &&
    buyer1.bidderId === buyer2.bidderId
  );
}

export function getDealBuyerOptions(deal: DealEntry['deal'], user?: User) {
  const buyers = user?.buyers?.length
      ? user.buyers
      : (user?.agencies?.flatMap((a) => a.buyers ?? []) ?? []),
    dealAgencyBuyers = user?.agencies?.find((a) => a.id === deal.ownedBy)?.buyers ?? [];

  return includeIfMissing(
    buyers.filter(
      (b) =>
        dealAgencyBuyers.length && dealAgencyBuyers.some((buyer) => isSameBuyer(buyer, b))
    ),
    deal.buyer
  ).map((buyer) => ({
    value: buyer,
    label: `${buyer.dsp} ${buyer.seat} ${buyer.bidderId}`,
  }));
}

type AdvertiserOption = {
  value: Advertiser;
  label: string;
};

export function getDealAdvertiserOptions(
  deal: DealEntry['deal'],
  user?: User
): AdvertiserOption[] {
  const advertisers = user?.advertisers?.length
      ? user.advertisers
      : (user?.agencies?.flatMap((a) => a.advertisers ?? []) ?? []),
    dealAgencyAdvertisers =
      user?.agencies?.find((a) => a.id === deal.ownedBy)?.advertisers ?? [];

  return includeIfMissing(
    advertisers.filter(
      (a) =>
        dealAgencyAdvertisers.length &&
        dealAgencyAdvertisers.some((adv) => a?.id === adv?.id)
    ),
    deal.advertiser
  ).map((advertiser) => ({ value: advertiser, label: advertiser.name }));
}

export function includeIfMissing<T>(
  options: Array<T>,
  value: T | null | undefined
): Array<T> {
  if (
    typeof value == 'undefined' ||
    value == null ||
    options.some((o) => isEqual(o, value))
  ) {
    return options;
  }

  return [value, ...options];
}

function cleanInventory(deal: Optional<DealAspects>): Optional<DealAspects> {
  const cleanedDeal = { ...deal };

  if (deal.format && deal.environments) {
    cleanedDeal.inventory = (cleanedDeal.inventory ?? []).reduce((agg, inv) => {
      if (inv.type !== 'channel') {
        agg.push(inv);
      }

      // Filter out channel ids that are not allowed by the format and/or the environment
      const filteredIds = filterInventoryIds(deal.environments!, inv.value, deal.format!);

      if (filteredIds.length > 0) {
        agg.push({
          ...inv,
          channelIds: filteredIds,
        } as InventoryChannel);
      }

      return agg;
    }, [] as Inventory[]);
  }

  if (!cleanedDeal.inventory || cleanedDeal.inventory.length === 0) {
    cleanedDeal.inventory = getDefaultInventory(deal.environments);
  }

  return cleanedDeal;
}

function filterInventoryIds(
  environments: Environment[],
  invName: string,
  formatName: string
): number[] {
  const ronChannelIds: Partial<Record<Environment, number[]>> | undefined = [
    RON_DPG_INVENTORY,
    RON_PPN_INVENTORY,
    ALL_INVENTORY,
  ].find((channel) => channel.value === invName)?.channelIds;

  if (ronChannelIds) {
    return environments
      .map((env) => ronChannelIds[env])
      .flatMap((idGroup) => idGroup ?? []);
  }

  const channelIds =
    INVENTORY_CHANNELS.find((ch) => ch.name === invName)?.channelIds ?? {};

  return environments
    .map((env) => channelIds[env])
    .flatMap((idGroup) => idGroup ?? [])
    .filter((c) => c.formats?.some((ps) => productSpecs[ps].name === formatName) ?? true)
    .map((c) => c.id);
}

function getDefaultInventory(
  environments: Environment[] | null | undefined
): Inventory[] {
  const defaultInvIds = (environments ?? ['web', 'app'])
    .map((env) => ALL_INVENTORY.channelIds[env])
    .flatMap((idGroup) => idGroup ?? []);

  return [
    {
      ...ALL_INVENTORY,
      channelIds: defaultInvIds,
    },
  ];
}
