import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryFunctionContext } from 'react-query';

import { Typography } from '@mui/material';

import { styles } from './styles';

import { Agency } from '../../api/agencies/types';
import { queryKeys } from '../../api/queryKeys';
import { getUser } from '../../api/users';
import { User } from '../../api/users/types';
import { getFullName } from '../../features/users/utils';
import { getRole, getUserRole, Roles } from '../../utils/keycloak/Keycloak';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { AdvertiserTooltips } from '../advertiser-tooltips/AdvertiserTooltips';
import { EditAdvertiserButton } from '../brand-suitability/EditAdvertiserButton';
import { RowWithTitle } from '../elements/row-with-title';

export type Props = {
  agency: Agency;
};

async function getDealManagers(ids: string[]) {
  return await Promise.all(
    ids.map((id) => {
      return getUser({
        queryKey: [queryKeys.getUser, id],
      } as QueryFunctionContext<[string, string]>);
    })
  );
}

export const AgencyDetails: React.FC<Props> = ({ agency }) => {
  const intl = useIntl();
  const ability = useAbility();
  const canViewDealManagers = ability.can(Actions.View, Permissions.AgencyDealManagers);
  const canViewIOId = useMemo(
    () => [Roles.DPGAdmin, Roles.DPGUser].includes(getRole()),
    []
  );
  const hasGamblingIO = agency.advertisers?.some((a) => a.isGambling) ?? false;
  const [dealManagers, setDealManagers] = useState<User[]>([]);

  useEffect(() => {
    if (canViewDealManagers && agency.dealManagers) {
      getDealManagers(agency.dealManagers).then((agencyDealManagers) =>
        setDealManagers(agencyDealManagers)
      );
    }
  }, [canViewDealManagers, agency]);

  return (
    <>
      <Typography variant="h2" sx={styles.title}>
        <FormattedMessage id="deal.detail.props.title" />
      </Typography>
      <RowWithTitle title={intl.formatMessage({ id: 'agency.view.name' })} mt={3} mb={3}>
        <Typography sx={styles.text}>{agency.name}</Typography>
      </RowWithTitle>
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.view.dealNameAlias' })}
        mt={3}
        mb={3}
      >
        <Typography sx={[styles.text, agency.dealNameAlias ? null : styles.mutedText]}>
          {agency.dealNameAlias ?? agency.name}
        </Typography>
      </RowWithTitle>
      {canViewDealManagers && (
        <RowWithTitle
          title={intl.formatMessage({ id: 'agency.view.dealManagers' })}
          mt={3}
          mb={3}
        >
          <Typography sx={styles.text}>
            {dealManagers.length > 0
              ? dealManagers.map((dealManager) => getFullName(dealManager)).join(', ')
              : ''}
          </Typography>
        </RowWithTitle>
      )}
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.view.salesForce' })}
        mt={3}
        mb={3}
      >
        <Typography sx={styles.text}>{agency.salesForceId}</Typography>
      </RowWithTitle>
      {canViewIOId && (
        <>
          <RowWithTitle
            title={intl.formatMessage({ id: 'agency.view.insertionOrderId' })}
            mt={3}
            mb={3}
          >
            <Typography sx={styles.text}>{agency.insertionOrderId}</Typography>
          </RowWithTitle>
          {hasGamblingIO && (
            <RowWithTitle
              title={intl.formatMessage({ id: 'agency.view.insertionOrderIdGambling' })}
              mt={3}
              mb={3}
            >
              <Typography sx={styles.text}>{agency.insertionOrderIdGambling}</Typography>
            </RowWithTitle>
          )}
        </>
      )}
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.view.buyers' })}
        mt={3}
        mb={3}
      >
        <Typography sx={styles.text}>
          {agency.buyers?.map((buyer, idx, arr) => (
            <React.Fragment key={`buyer-${idx}`}>
              {`${buyer.dsp} ${buyer.seat}`}
              {idx < arr!.length - 1 && <br />}
            </React.Fragment>
          )) ?? '-'}
        </Typography>
      </RowWithTitle>{' '}
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.view.advertisers' })}
        mt={3}
        mb={3}
      >
        <Typography sx={styles.text}>
          {agency.advertisers?.map((a, idx, arr) => (
            <React.Fragment key={`advertiser-${idx}`}>
              {a.name}
              <AdvertiserTooltips advertiser={a} />
              <EditAdvertiserButton agency={agency} advertiser={a} index={idx} />
              {idx < arr.length - 1 && <br />}
            </React.Fragment>
          )) ?? '-'}
        </Typography>
      </RowWithTitle>{' '}
      <RowWithTitle
        title={intl.formatMessage({ id: 'agency.view.isPrio' })}
        mt={3}
        mb={3}
      >
        <Typography sx={styles.text}>
          <FormattedMessage
            id={agency.isPrio ? 'agency.view.isPrio.true' : 'agency.view.isPrio.false'}
          />
        </Typography>
      </RowWithTitle>
    </>
  );
};
