import React, { CSSProperties, useLayoutEffect, useState } from 'react';

import { IconButton } from '@mui/material';
import Icon from '@mui/material/Icon';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { styles } from './styles';

interface Props extends TextFieldProps<'standard'> {
  value: string;
  onUpdate: (value: string) => void;
  onCancel?: () => void;
  alwaysShowCancel?: boolean;
}

export const ConfirmableTextField: React.FC<Props> = ({
  value: baseValue,
  onUpdate,
  onCancel,
  alwaysShowCancel = false,
  ...props
}) => {
  const [value, setValue] = useState(baseValue),
    isChanged = baseValue !== value;

  useLayoutEffect(() => {
    setValue(baseValue);
  }, [baseValue]);

  return (
    <div
      style={
        {
          ...styles.container,
          ...props.sx,
        } as CSSProperties
      }
    >
      <TextField
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        {...props}
        InputProps={{
          sx: styles.input,
          ...props.InputProps,
        }}
      />
      <div style={styles.actions as CSSProperties}>
        {isChanged && (
          <IconButton
            aria-label="confirm"
            size="small"
            onClick={(e) => {
              onUpdate(value);
              e.stopPropagation();
            }}
          >
            <Icon fontSize="inherit">check_circle</Icon>
          </IconButton>
        )}
        {(alwaysShowCancel || isChanged) && (
          <IconButton
            aria-label="revert"
            size="small"
            onClick={(e) => {
              setValue(baseValue);
              onCancel?.();
              e.stopPropagation();
            }}
          >
            <Icon fontSize="inherit">cancel</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};
